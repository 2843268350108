import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import styled from 'styled-components';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CloseIconWrap from '../CloseIconWrap';
import { dynamicGetApi, dynamicPostApi } from '../../API';
import { setSelectedClinicId, setRecall } from '../../Redux/Slice/commonSlice';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '50%',
    transform: 'translate(-50%, -50%)',
    borderColor: '#fff',
    bgcolor: 'background.paper',
    borderRadius: '25px',
    boxShadow: 24,
    pt: 1.8,
    pb: 1.8,
    px: 2,
};
const ModelTitle = styled.h3`
font-size:20px;
font-weght:500 !important;
text-align:center;
margin:0px;
color:#1a1a1a !important;
`
export const SolidTextField = styled(TextField)({
    borderRadius: '8px',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#f5f5f5',
        },
        '&:hover fieldset': {
            borderColor: '#f5f5f5',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#f5f5f5',
        },
    },
});
export const RequiredText = styled.div`
font-size:13px;
color: #d32f2f;
font-weight:400;
padding-left:15px;
`
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    height: '45px',
    borderRadius: '8px',
    '& .MuiInputBase-input': {
        backgroundColor: '#f5f5f5',
        border: 'none',
        '&:focus': {
            backgroundColor: '#f5f5f5',
            border: 'none',
        },
    },
}));


function AddEditClinic(props) {
    const [cookie, setCookie, removeCookie] = useCookies();
    let navigate = useNavigate();
    let dispatch = useDispatch();

    let selectedClinicId = useSelector(state => state.common.selectedClinicId);

    const formik = useFormik({
        initialValues: {
            id: null,
            name: '',
            token: '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Required *"),
            token: Yup.string().required("Required *"),
        }),
        onSubmit: async values => {
            let url = "api/clinic/addOrUpdateClinic";
            let res = await dynamicPostApi(values, url, navigate, setCookie, dispatch)
            if (res.data) {
                dispatch(setSelectedClinicId(null));
                dispatch(setRecall(true));
                onClose();
            }
        },
    });

    useEffect(() => {
        if (!!selectedClinicId) {
            getClinic();
        }
    }, [selectedClinicId]);

    const onClose = () => {
        dispatch(setSelectedClinicId(null));
        formik.resetForm();
        props.hideModal();
    }

    const getClinic = async () => {
        let url = "api/clinic/getClinicById?clinicId=" + selectedClinicId;
        let res = await dynamicGetApi(url, navigate, setCookie, dispatch);
        if (res.data) {
            formik.setFieldValue("id", res.data.id);
            formik.setFieldValue("name", res.data.clinicName);
            formik.setFieldValue("token", res.data.token);
        }
    }

    return (
        <>
            <div>
                <Modal
                    open={props.isShow}
                    onClose={() => onClose()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="position-r"
                >
                    <Grid container>
                        <Grid item md={12} sm={12} xs={12}>
                            <Box sx={style}>
                                <Box className='d-flex justify-content-between align-items-center' sx={{ pb: 1, borderBottom: '1px solid #ebebeb', position: 'relative' }}>
                                    <Box>
                                        <ModelTitle>{formik.values.id > 0 ? formik.values.name + ' Bearbeiten Klinik' : "Klinik Hinzufügen"}</ModelTitle>
                                    </Box>
                                    <Box>
                                        <Box sx={{ position: 'absolute', top: '-50px', right: '-55px' }} onClick={() => onClose()}>
                                            <CloseIconWrap />
                                        </Box>
                                        <Button sx={{ mr: 2 }} type='button' onClick={formik.handleSubmit} className='btn-light'>Speichern</Button>
                                    </Box>
                                </Box>
                                <Box className="InviteColleague-modal">
                                    <Box sx={{ pb: 2 }}>
                                        <Grid container spacing={2} sx={{ mt: 1 }}>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <FormLabel>Clinic Name</FormLabel>
                                                <SolidTextField
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    placeholder='Clinc Name'
                                                    id="name"
                                                    name="name"
                                                    size="small"
                                                    type="text"
                                                    sx={{ pt: '5px', mb: 1.5, mt: 1, height: '45px', backgroundColor: '#f5f5f5', }}
                                                    error={formik.errors.name && formik.touched.name ? true : false}
                                                    helperText={formik.errors.name && formik.touched.name ? formik.errors.name : ""}
                                                    value={formik.values.name}
                                                    onChange={formik.handleChange}
                                                />
                                            </Grid>
                                            {formik.values.id !== null && formik.values.id > 0 &&
                                                <Grid item md={12} sm={12} xs={12}>
                                                    <FormLabel>Clinic Id</FormLabel>
                                                    <SolidTextField
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        placeholder='Clinc Id'
                                                        id="id"
                                                        name="id"
                                                        size="small"
                                                        type="text"
                                                        sx={{ pt: '5px', mb: 1.5, mt: 1, height: '45px', backgroundColor: '#f5f5f5', }}
                                                        error={formik.errors.id && formik.touched.id ? true : false}
                                                        helperText={formik.errors.id && formik.touched.id ? formik.errors.id : ""}
                                                        value={formik.values.id}
                                                        onChange={formik.handleChange}
                                                        inputProps={{ readOnly: true }}
                                                    />
                                                </Grid>}
                                            <Grid item md={12} sm={12} xs={12}>
                                                <FormLabel>Clinic Token</FormLabel>
                                                <SolidTextField
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    placeholder='Clinic Token'
                                                    id="token"
                                                    name="token"
                                                    size="small"
                                                    type="text"
                                                    sx={{ pt: '5px', mb: 1.5, mt: 1, height: '45px', backgroundColor: '#f5f5f5', }}
                                                    error={formik.errors.token && formik.touched.token ? true : false}
                                                    helperText={formik.errors.token && formik.touched.token ? formik.errors.token : ""}
                                                    value={formik.values.token}
                                                    onChange={formik.handleChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Modal>
            </div>
        </>
    );
}

export default AddEditClinic;