import './App.css';
import { Routes, Route} from 'react-router-dom';
import MainLayout from './Layout/MainLayout';
import ManageClinic from './Pages/ManageClinic';
import Dashboard from './Pages/Dashboard';
import AccountLayout from './Layout/AccountLayout/Index';
import Login from './Pages/Login';
import Team from './Pages/Team';

function App() {
  return (
    <div className="App">
      <Routes>
      <Route path='/' element={<AccountLayout />}>
          <Route index element={<Login />} />
        </Route>
        <Route element={<MainLayout/>}>
           <Route path="dashboard" element={<Dashboard/>} />
           <Route path='/dashboard/manageClinic' element={<ManageClinic />} />
           <Route path='/dashboard/team/:id' element={<Team />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
