import React, { useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Typography } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { DropdownWrapper } from '../HeaderDropdownWrapper';
import styled from 'styled-components';
import DataTable from '../DataTable';


const Badge = styled.span`
padding: 7px 18px !important;
background-color:${props => props.danger ? '#fff5f8' : '#e8fff3'};
color: ${props => props.danger ? '#f1416c' : '#50cd89'};
border-radius: 20px !important;
text-transform: capitalize !important;
cursor:pointer;
&:hover{
    background-color:${props => props.danger ? '#f1416c' : '#50cd89'};
    color:#fff; 
}
`

function ClinicTable(props) {
    const columns = useMemo(
        () => [
            {
                name: "Klinik Id",
                sortable: true,
                sortField: "id",
                selector: (row) => row.id,
            },
            {
                name: "Klinik Name",
                sortable: true,
                sortField: "clinicName",
                selector: (row) => row.clinicName,
            },
            {
                name: "DS Worker",
                sortable: false,
                sortField: "dsWorkerStatus",
                selector: (row) => row.dsWorkerStatus,
            },
            {
                name: "GEN Worker",
                sortable: false,
                sortField: "generalWorkerStatus",
                selector: (row) => row.generalWorkerStatus,
            },
            {
                name: "Actions",
                cell: (row) => {
                    return (
                        <div>
                            <Box display="flex" justifyContent="end" alignItems="center">
                                <DropdownWrapper bodyType={"TableAction"} clinicId={row.id} setFilesModal={props.setViewFilesModal} setClinicModal={props.setAddEditClinicModal} tableName="ManageClinic" noarrow iconBg={false} icon={<MoreHorizIcon style={{ color: '#AB9892' }} />} />
                            </Box>
                        </div>
                    );
                },
            },
        ],
        []
    );
    return (
        <>
            <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ color: '#1a1a1a' }}><b>Klinik Id</b></TableCell>
                            <TableCell sx={{ color: '#1a1a1a' }}><b>Klinik Name</b></TableCell>
                            <TableCell align='center' sx={{ color: '#1a1a1a' }}><b>DS Worker</b></TableCell>
                            <TableCell align='center' sx={{ color: '#1a1a1a' }}><b>GEN Worker</b></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(props.clinicList).length > 0 ? props.clinicList.map(x =>
                            <TableRow>
                                <TableCell >
                                    <Box display="flex" justifyContent="start" alignItems="center">
                                        <Box>
                                            <Typography varient='p' component='p' sx={{ fontSize: '16px', margin: 0 }}>{x.id}</Typography>
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell >
                                    <Box display="flex" justifyContent="start" alignItems="center">
                                        <Box>
                                            <Typography varient='p' component='p' sx={{ fontSize: '16px', margin: 0 }}>{x.clinicName}</Typography>
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell align='center'>
                                    <Badge onClick={() => props.changeDsWorkerStatus(x)} danger={!x.dsWorkerStatus}>{x.dsWorkerStatus ? "Authorized" : "Unauthorized"}</Badge>
                                </TableCell>
                                <TableCell align='center'>
                                    <Badge onClick={() => props.changeGeneralWorkerStatus(x)} danger={!x.generalWorkerStatus}>{x.generalWorkerStatus ? "Authorized" : "Unauthorized"}</Badge>
                                </TableCell>
                                <TableCell align='end'>
                                    <Box display="flex" justifyContent="end" alignItems="center">
                                        <DropdownWrapper bodyType={"TableAction"} clinicId={x.id} setFilesModal={props.setViewFilesModal} setClinicModal={props.setAddEditClinicModal} tableName="ManageClinic" noarrow iconBg={false} icon={<MoreHorizIcon style={{ color: '#AB9892' }} />} />
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) :
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <Box display="flex" justifyContent="center" alignItems="center">
                                        <Box>
                                            <Typography varient='p' component='p' sx={{ fontSize: '16px', margin: 0 }}>No data available</Typography>
                                        </Box>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <DataTable columns={columns} url={"api/clinic/getPagination"} /> */}
        </>
    );
}

export default ClinicTable;