import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import { ContentWrapper, InnerContent } from '../Components/StyledComponents';
import SideMenu from './SideMenu';


function MainLayout(props) {

    return (
        <>
            <Header />
            <ContentWrapper>
                <Box sx={{ flexGrow: 1 }} className='my-practiceSetting'>
                    <Card maxWidth="xs" sx={{ borderRadius: '20px', boxShadow: 'none' }}>
                        <CardContent sx={{ pt: 0 }} className="pb-0px">
                            <Grid container sx={{ py: 5 }}>
                                <Grid lg={2.5} md={3} sm={12} xs={12} sx={{ borderRight: '1px solid #ebebeb', pr: 2 }}>
                                    <SideMenu />
                                </Grid>
                                <Grid lg={9.5} md={9} sm={12} xs={12}>
                                    <InnerContent>
                                        <Outlet />
                                    </InnerContent>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            </ContentWrapper>

        </>
    );
}

export default MainLayout;