import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import styled from 'styled-components';
import ImageUploader from '../ImageUploader';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputBase from '@mui/material/InputBase';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { dynamicGetApi, dynamicPostApi } from '../../API';
import Swal from 'sweetalert2';
import { setRecall, setIsEdit } from './../../Redux/Slice/commonSlice';
import { setSeletedUserId } from './../../Redux/Slice/userSlice';
import CloseIconWrap, { CloseLeftCornor } from '../CloseIconWrap';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { CheckedIcon, UnCheckedIcon } from '../RodioButton';
import jwt_decode from "jwt-decode";
import { relativeTimeRounding } from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useParams } from "react-router-dom";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '50%',
    transform: 'translate(-50%, -50%)',
    borderColor: '#fff',
    bgcolor: 'background.paper',
    borderRadius: '25px',
    boxShadow: 24,
    pt: 1.8,
    pb: 1.8,
    px: 2,
};
const ModelTitle = styled.h3`
font-size:20px;
font-weght:500 !important;
text-align:center;
margin:0px;
color:#1a1a1a !important;
`
// const ModalCloseIcon = styled.span`
// display:flex;
// align-items:center;
// padding:5px;
// background-color:#f5f5f5;
// border-radius:50%;
// `
export const SolidTextField = styled(TextField)({
    borderRadius: '8px',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#f5f5f5',
        },
        '&:hover fieldset': {
            borderColor: '#f5f5f5',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#f5f5f5',
        },
    },
});

export const RequiredText = styled.div`
font-size:13px;
color: #d32f2f;
font-weight:400;
padding-left:15px;
`

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    height: '45px',
    borderRadius: '8px',
    '& .MuiInputBase-input': {
        backgroundColor: '#f5f5f5',
        border: 'none',
        '&:focus': {
            backgroundColor: '#f5f5f5',
            border: 'none',
        },
    },
}));

function InviteColleague(props) {
    const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const { id } = useParams();

    const isEdit = useSelector(state => state.common.isEdit);
    const seletedUserId = useSelector(state => state.user.seletedUserId);

    const [roleList, setRoleList] = useState([]);
    const [userImgSrc, setUserImgSrc] = useState('');
    const [editSwal, setEditSwal] = useState(false);

    const invitattionAddValidationSchema = Yup.object().shape({
        firstName: Yup.string().required("Required *"),
        lastName: Yup.string().required("Required *"),
        userName: Yup.string().required("Required *"),
        email: Yup.string().required("Required *"),
        role: Yup.string().required("Required *"),
    });
    const invitattionUpdateValidationSchema = Yup.object().shape({
        userName: Yup.string().required("Required *"),
        email: Yup.string().required("Required *"),
        role: Yup.string().required("Required *"),
    });

    const formik = useFormik({
        initialValues: {
            id: 0,
            firstName: '',
            lastName: '',
            userName: '',
            email: '',
            role: localStorage.getItem("userRole") === "Admin" ? '' : 'Mitarbeiter:in',
            userImage: '',
            version: 0,
            clinicId: id
        },
        validationSchema: isEdit !== true ? invitattionAddValidationSchema : invitattionUpdateValidationSchema,
        onSubmit: async values => {
            let url = 'api/user/inviteUser';
            var form_data = new FormData();

            for (var key in values) {
                form_data.append(key, values[key]);
            }
            let result = await dynamicPostApi(form_data, url, navigate, setCookie, dispatch);
            if (result.data) {
                setCookie("selectedVersion", values.version.toString() === "1" ? "old" : "new");
                onClose();
                let swalTitle = editSwal === true ? 'Updated' : 'Invitation Sent';
                let swalText = editSwal === true ? 'Updated successfully!' : 'Invitation sent successfully!';
                Swal.fire({
                    title: swalTitle,
                    text: swalText,
                    icon: 'success',
                    confirmButtonColor: '#3085d6',
                }).then((result) => {
                    if (result.isConfirmed) {
                        dispatch(setRecall(true));
                    }
                })
            }
        },
    });

    const setImage = (img) => {
        formik.setFieldValue("userImage", img);
    }

    useEffect(() => {
        if (props.isShow) {
            getPageData();
        }
    }, [props.isShow]);

    useEffect(() => {
        if (props.isShow && isEdit) {
            setEditSwal(true);
            getUserData();
        }
    }, [isEdit]);

    const getPageData = async () => {
        let url = "api/role/getRoles";
        let roleListRes = await dynamicGetApi(url, navigate, setCookie, dispatch);
        setRoleList(roleListRes.data);
    }

    const getUserData = async () => {
        let getSelectedUserUrl = "api/user/getUserById?userId=" + seletedUserId;
        let selectedUserRes = await dynamicGetApi(getSelectedUserUrl, navigate, setCookie, dispatch);
        setUserImgSrc(selectedUserRes.data.image);
        formik.setFieldValue("id", selectedUserRes.data.id);
        formik.setFieldValue("firstName", selectedUserRes.data.firstName);
        formik.setFieldValue("lastName", selectedUserRes.data.lastName);
        formik.setFieldValue("userName", selectedUserRes.data.fullName);
        formik.setFieldValue("email", selectedUserRes.data.email.trim());
        formik.setFieldValue("role", selectedUserRes.data.role);
        formik.setFieldValue("userImage", selectedUserRes.data.image);
        formik.setFieldValue("version", selectedUserRes.data.version.toString());
    }

    const onClose = () => {
        formik.resetForm();
        props.hideColleagueModal();
        dispatch(setIsEdit(false));
        dispatch(setSeletedUserId(0));
    }

    const handleVersionChange = (e) => {
        formik.setFieldValue("version", e.target.value);
    }

    return (
        <>
            <div>
                <Modal
                    open={props.isShow}
                    onClose={() => onClose()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="position-r"
                >
                    <Grid container>
                        <Grid item md={12} sm={12} xs={12}>
                            <Box sx={style}>
                                <Box className='d-flex justify-content-between align-items-center' sx={{ pb: 1, borderBottom: '1px solid #ebebeb', position: 'relative' }}>
                                    <Box>
                                        <ModelTitle>{formik.values.id > 0 ? formik.values.userName + ' bearbeiten' : "Kolleg:in einladen"}</ModelTitle>
                                    </Box>
                                    <Box>
                                        <Box sx={{ position: 'absolute', top: '-50px', right: '-55px' }} onClick={() => onClose()}>
                                            <CloseIconWrap />
                                        </Box>
                                        <Button sx={{ mr: 2 }} type='button' onClick={formik.handleSubmit} className='btn-light'>{formik.values.id ? 'Speichern' : 'Einladen'}</Button>
                                    </Box>
                                </Box>
                                <Box className="InviteColleague-modal">
                                    <Box>
                                        <ImageUploader setUserImage={setImage} isEditMode={isEdit} imgSrc={userImgSrc} />
                                    </Box>
                                    <Box>
                                        <Grid container spacing={2} sx={{ mt: 2 }}>
                                            {isEdit !== true ?
                                                <>
                                                    <Grid item md={6} sm={6} xs={12}>
                                                        <FormLabel>Vorname</FormLabel>
                                                        <SolidTextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            placeholder='Vorname'
                                                            id="firstName"
                                                            name="firstName"
                                                            size="small"
                                                            type="text"
                                                            sx={{ pt: '5px', mb: 1.5, mt: 1, height: '45px', backgroundColor: '#f5f5f5', }}
                                                            error={formik.errors.firstName && formik.touched.firstName ? true : false}
                                                            helperText={formik.errors.firstName && formik.touched.firstName ? formik.errors.firstName : ""}
                                                            value={formik.values.firstName}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </Grid>
                                                    <Grid item md={6} sm={6} xs={12}>
                                                        <FormLabel>Nachname</FormLabel>
                                                        <SolidTextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            placeholder='Nachname'
                                                            id="lastName"
                                                            name="lastName"
                                                            size="small"
                                                            type="text"
                                                            sx={{ pt: '5px', mb: 1.5, mt: 1, height: '45px', backgroundColor: '#f5f5f5', }}
                                                            error={formik.errors.lastName && formik.touched.lastName ? true : false}
                                                            helperText={formik.errors.lastName && formik.touched.lastName ? formik.errors.lastName : ""}
                                                            value={formik.values.lastName}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </Grid>
                                                </>
                                                : null}
                                            <Grid item md={12} sm={12} xs={12}>
                                                <FormLabel>Vor-und Nachname</FormLabel>
                                                <SolidTextField
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    placeholder='Vor-und Nachname'
                                                    id="userName"
                                                    name="userName"
                                                    size="small"
                                                    type="text"
                                                    sx={{ pt: '5px', mb: 1.5, mt: 1, height: '45px', backgroundColor: '#f5f5f5', }}
                                                    error={formik.errors.userName && formik.touched.userName ? true : false}
                                                    helperText={formik.errors.userName && formik.touched.userName ? formik.errors.userName : ""}
                                                    value={formik.values.userName}
                                                    onChange={formik.handleChange}
                                                />
                                            </Grid>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <FormLabel>E-Mail</FormLabel>
                                                <SolidTextField
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    placeholder='E-Mail'
                                                    id="email"
                                                    name="email"
                                                    size="small"
                                                    type="text"
                                                    sx={{ pt: '5px', mb: 1.5, mt: 1, height: '45px', backgroundColor: '#f5f5f5', }}
                                                    error={formik.errors.email && formik.touched.email ? true : false}
                                                    helperText={formik.errors.email && formik.touched.email ? formik.errors.email : ""}
                                                    value={formik.values.email}
                                                    onChange={formik.handleChange}
                                                />
                                            </Grid>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <FormLabel id="role">Rolle</FormLabel>
                                                <Select
                                                    labelId="role"
                                                    id="demo-simple-select"
                                                    value={formik.values.role}
                                                    name='role'
                                                    label=""
                                                    input={<BootstrapInput />}
                                                    sx={{ width: '100%', height: '45px', backgroundColor: '#f5f5f5' }}
                                                    onChange={formik.handleChange}
                                                >
                                                    {roleList.map(x =>
                                                        <MenuItem value={x.value}>{x.label}</MenuItem>
                                                    )}
                                                </Select>
                                                <RequiredText>
                                                    {formik.touched.role && formik.errors.role
                                                        ? formik.errors.role
                                                        : null}
                                                </RequiredText>
                                            </Grid>
                                            {formik.values.id > 0 && parseInt(jwt_decode(cookies.authToken).UserID) === formik.values.id &&
                                                <Grid item md={12} sm={12} xs={12}>
                                                    <FormLabel id="version-controll">Version</FormLabel>
                                                    <FormControl fullWidth={true}>
                                                        <RadioGroup
                                                            aria-labelledby="version-controll"
                                                            name="version"
                                                            onChange={(e) => handleVersionChange(e)}
                                                        >
                                                            <Grid container>
                                                                <Grid item md={6} sm={12} xs={12}>
                                                                    <FormControlLabel control={<Radio checked={formik.values.version === "1"} value="1" disableRipple color="default" checkedIcon={<CheckedIcon />} icon={<UnCheckedIcon />} />} label="Old Version" />
                                                                </Grid>
                                                                <Grid item md={6} sm={12} xs={12}>
                                                                    <FormControlLabel control={<Radio checked={formik.values.version === "2"} value="2" disableRipple color="default" checkedIcon={<CheckedIcon />} icon={<UnCheckedIcon />} />} label="New Version" />
                                                                </Grid>
                                                            </Grid>
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Modal>
            </div>
        </>
    );
}

export default InviteColleague;