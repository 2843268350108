import React, { useState } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DashboardIcon from '@mui/icons-material/Dashboard';
const theme = createTheme({
    components: { MuiMenuItem: { styleOverrides: { root: { '&:hover': { backgroundColor: '#f4f7ff' }, '&:active': { backgroundColor: "#f4f7ff" }, "&.Mui-selected,&.Mui-selected:hover": { backgroundColor: "#f4f7ff" } } } } }
})



function SideMenu() {
    let navigate = useNavigate();
    const [DashboardSelect, setDashboardSelect] = useState(true);
    const [clinicSelected, setClinicSelected] = useState(false);
    const [TeamSelected, setTeamSelected] = useState(false);

    const dashboardClick = () => {
        setDashboardSelect(true);
        setClinicSelected(false);
        setTeamSelected(false);
        navigate("dashboard");
    }
    const clinicClick = () => {
        setClinicSelected(true);
        setDashboardSelect(false);
        setTeamSelected(false);
        navigate("dashboard/manageClinic");
    }

    const teamClick = () => {
        setTeamSelected(true);
        setDashboardSelect(false);
        setClinicSelected(false);
        navigate("dashboard/team");
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <MenuList className='list-praxis-mb'>
                    <MenuItem selected={DashboardSelect} onClick={dashboardClick} sx={{ py: '10px', borderRadius: '7px', mt: 1, "&:hover": { color: "#120EC5" } }} className='practice-menu'>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <DashboardIcon sx={{ mr: 1 }} />
                            <Typography variant='' component='p' sx={{ fontWeight: '400', m: 0, mt: '2px', color: '#1a1a1a' }}>Dashboard</Typography>
                        </Box>
                    </MenuItem>
                    {/* <MenuItem selected={TeamSelected} onClick={teamClick} sx={{ py: '10px', borderRadius: '7px', mt: 1, "&:hover": { color: "#120EC5" } }} className='practice-menu'>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <SettingsOutlinedIcon sx={{ mr: 1 }} />
                            <Typography variant='' component='p' sx={{ fontWeight: '400', m: 0, mt: '2px', color: '#1a1a1a' }}>Team</Typography>
                        </Box>
                    </MenuItem> */}
                    <MenuItem selected={clinicSelected} onClick={clinicClick} sx={{ py: '10px', borderRadius: '7px', mt: 1, "&:hover": { color: "#120EC5" } }} className='practice-menu'>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <SettingsOutlinedIcon sx={{ mr: 1 }} />
                            <Typography variant='' component='p' sx={{ fontWeight: '400', m: 0, mt: '2px', color: '#1a1a1a' }}>Klinik Verwalten</Typography>
                        </Box>
                    </MenuItem>
                </MenuList>
            </ThemeProvider>
        </>
    );
}

export default SideMenu;