import styled from 'styled-components';

export const HeaderWrapper = styled.div`
background-color:#f8f6f5 !important; 
box-shadow: 0 2px 5px #c9c9c9;
padding-top:20px;
padding-bottom:25px;
top: 0;
left: 0;
right: 0;
position: fixed;
`

export const FlexStart = styled.div`
display:flex;
justify-content: start;
align-items : ${props => props.verticalAlign ? props.verticalAlign : 'center'}
`
export const FlexBetween = styled.div`
display:flex;
justify-content: space-between;
align-items : ${props => props.verticalAlign ? props.verticalAlign : 'center'}
`
export const FlexEnd = styled.div`
display:flex;
justify-content: end;
align-items : ${props => props.verticalAlign ? props.verticalAlign : 'center'}
`
export const FlexCenter = styled.div`
display:flex;
justify-content: center;
align-items : ${props => props.verticalAlign ? props.verticalAlign : 'center'}
`


export const AddSpace = styled.div`
margin: 15px 0;
`
export const PlaneButton = styled.div`
background:#efeae6;
padding:3px 5px;
border-radius: 10px;
display:flex;
align-items:center;
color:#120ec5;
height: 26px;
cursor: pointer;
`

export const PlaneButtonDisable = styled.div`
background:#dddddd;
padding:3px 5px;
border-radius: 10px;
display:flex;
align-items:center;
color:#fff;
height: 26px;
cursor: pointer;
`

export const HandEmoji = styled.span`
font-size:16px;
`

export const HeaderButton = styled.button`
border:none ;
background-color:transparent !important;
font-size:16px;
padding:5px 10px;
font-weight:500;
border-radius:8px;
align-self:center;
display: flex;
align-items: center;
cursor:pointer;
&:hover{
    background-color:#f3efec !important;
    outline:none !important;
}
`
export const ChatTemplateHeaderButton = styled.button`
border:none ;
background-color:transparent !important;
font-size:14px;
padding:8px 15px;
font-weight:500;
align-self:center;
display: flex;
align-items: center;
cursor:pointer;
color:#120ec5 !important;
&:hover{
    background-color:#f3efec !important;
    outline:none !important;
    border-radius:20px;
}
`
export const GallenbachButton = styled.button`
border:none ;
background-color:transparent !important;
font-size:16px;
padding:5px 10px;
font-weight:500;
border-radius:8px;
align-self:center;
display: flex;
align-items: center;
cursor:pointer;
&:hover{
    background-color:#f5f5f5 !important;
    outline:none !important;
}
`
export const HeaderButtonLeftIcon = styled.div`

${props => props.bg && `background-color:#efeae6 !important;`}
${props => props.newBg && `background-color:${props.newBg};`}
padding:5px;
border-radius:6px;
color:${props => props.color ? props.color : "#ab9892"};
margin-right:5px;
font-size:22px;
display: flex;

`
export const HeaderButtonarrow = styled.div`
color:#ab9892;
font-size:26px;
display: flex;

`
export const EllipsisText = styled.div`
white-space: nowrap; 
width: 50px; 
overflow: hidden;
text-overflow: ellipsis; 
`
export const CustomWrapper = styled.div`
padding:10px;
width:${props => props.width ? props.width : "250px"};
& .SearchBarWrapper{
  margin: 15px 0;
}
`
export const PlanarternHeading = styled.div`
font-weight:500;
margin-bottom:10px;

`
export const ListWrapper = styled.ul`
margin:0;
padding:0;
`
export const ListItem = styled.li`
margin:0;
list-style-type:none;
border-radius:10px;
padding: 0 15px;
margin-bottom:5px;
margin-left: -8px;

&:hover{
  background-color:#efeae6;
}
`
export const PlanarternFooter = styled.div`
border-top:1px solid #f5f5f5;
padding:10px 10px 0px 10px;
text-align:center;
margin-left:-17px;
margin-right:-17px;
color:#6f6f6f;
& .colorBlue{
  color:#120ec5;

}
`
export const BlueNotification = styled.div`
 width:22px;
 height:22px;
 background-color:#120ec5;
 color:#fff;
 font-size:14px;
 border-radius:50%;
 margin-right:10px;
 font-weight:500;
`
export const PaddingLeft15 = styled.div`
padding-left:13px;
`
export const BlueAnchor = styled.div`
color : #120ec5;
& .addMarginRight{
  margin-right:8px;
}
`
export const FeedbackTextAreaWrapper = styled.div`
background-color:#f5f5f5;
border:1px solid #e8e8e8;
outline:none !important;
padding: 15px;
border-radius:10px;

`
export const FeedbackTextArea = styled.textarea`
width:100%;
background-color:#f5f5f5;
border:none;
outline:none !important;
resize: none;
font-size: 15px;
&::placeholder{
  color:#bdbdbd
}
`
export const ButtonAbsenden = styled.div`
margin-top:20px;
& .pill{
  border-radius:50px
}
`
export const BlueBtn = styled.div`
& .pill{
  background-color: #120ec5;
  color:#fff;
  &:hover{
    background-color:#0e0ba0;
  }
}
& .pill::disabled{
  color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
}
`
export const EditText = styled.span`
font-size:16px;
color:#1a1a1a;
margin-left:5px;
`
export const EditFileHeader = styled.div`
display:flex;
align-items:center;
width:350px;
border-bottom: 1px solid rgba(224, 224, 224, 1);
padding-bottom:15px;
padding-top:10px;
`
export const EditFileHeaderTitle = styled.div`
width:90%;
text-align:center;
font-weight:500;
`
export const ChatTemplateHeaderTitle = styled.div`
text-align:center;
font-weight:500;
`
export const EditFileHeaderIcon = styled.div`
width:10%;
text-align:center;
`
export const EditFileBody = styled.div`
padding-top:15px;
padding-bottom:10px;
`
export const MemberlistBox = styled.div`
width:220px;
`
export const ChatTemplateBox = styled.div`
width:320px;
`
export const MemberlistHeader = styled.div`
display:flex;
align-items:center;
border-bottom: 1px solid rgba(224, 224, 224, 1);
padding-bottom:10px;
padding-top:7px;
`
export const ChatTemplateHeader = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
border-bottom: 1px solid rgba(224, 224, 224, 1);
padding-bottom:10px;
padding-top:7px;
padding-left:15px !important;
padding-right:15px !important;
`
export const MemberlistBody = styled.div`
padding-top:0px;
padding-bottom:0px;
`
export const ChatTemplateBody = styled.div`
padding-top:0px;
padding-bottom:0px;
`
export const IconTextSearch = styled.div`
border-radius:50%;
background-color:#0f5481;
width:30px;
height:30px;
color:#fff;
display: flex;
align-items: center;
justify-content: center;
margin-right: 10px;
`
export const ChatTemplateList = styled.div`
padding-left:15px !important;
padding-right:15px !important;
display:flex;
align-items:center;
justify-content:space-between;
&:hover{
  background-color:rgb(224 224 224 / 60%);
}
`
export const ChatTemplateTitle = styled.div`
font-size:14px;
padding-top:8px !important;
padding-bottom:8px !important;
width:85%;
border-bottom: 1px solid rgb(224 224 224 / 60%);
&:hover{
  border-bottom: 1px solid rgb(224 224 224 / 0%);
}
`
export const ChatTemplateIcon = styled.div`
padding-top:8px !important;
padding-bottom:8px !important;
`

export const ApplicationName = styled.div`
font-size:25px;
font-weight: 700;
color: #120ec5;
`
export const ContentWrapper = styled.div`
width:90%;
margin-left:auto;
margin-right:auto;
margin-top: 7%;
`
export const HeaderContent = styled.div`
width:90%;
margin-left:auto;
margin-right:auto;
`

export const DashboardImage = styled.img`
width: 100%;
height:auto:
object-fit:contain;
border-radius: 20px;
`
export const InnerContent = styled.div`
padding-left:16px;
`