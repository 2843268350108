import Container from '@mui/material/Container';
import React, { useState } from 'react';
import BusinessIcon from '@mui/icons-material/Business';
import { DropdownWrapper } from '../Components/HeaderDropdownWrapper';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { HeaderWrapper,FlexBetween, AddSpace, ApplicationName,HeaderContent } from '../Components/StyledComponents';



function Header() {
    const [isHeaderHide, setIstHeaderHide] = useState(true);

    return (
        <div className='mobile-header-wrapper'>
            <div className='mobile-menu-icon'>
                {isHeaderHide ?
                    <MenuIcon className='mob-icon' onClick={() => setIstHeaderHide(false)} sx={{ color: '#AB9892', fontSize: '40px' }} />
                    :
                    <CloseIcon className='mob-icon' onClick={() => setIstHeaderHide(true)} sx={{ color: '#AB9892', fontSize: '40px' }} />
                }
            </div>
            <HeaderWrapper className={`${isHeaderHide ? "Header" : "Header d-block"}`} >
                <HeaderContent>
                    <FlexBetween className='desktop-header'>
                        <div className='w-100'>
                            {/* <GallenbachTitle /> */}
                            <ApplicationName>HKP-Hero Admin</ApplicationName>
                        </div>
                        <div className='w-100 toRight d-flex align-items-center justify-content-end rightHeaderButtons'>
                            <LogoutText />
                        </div>
                    </FlexBetween>
                    <div className='d-flex align-items-center justify-content-between mobile-header'>
                        <div>
                            <GallenbachTitle />
                        </div>
                        <div>
                            <LogoutText />
                        </div>
                    </div>
                </HeaderContent>
            </HeaderWrapper>
        </div>
    );
}

export default Header;

export const GallenbachTitle = () => {
    return (
        <>
            <div>
                <DropdownWrapper bodyType={"GallenbachZahneZahnarztpraxis"} iconBg={true} icon={<BusinessIcon fontSize='inherit' />} title="Gallenbach & Zähne" />
            </div>
        </>
    )
}
export const LogoutText = () => {
    return (
        <>
            <div>
                <DropdownWrapper position={'right'} bodyType={"ProfileDropdown"} buttonClass="UsernameBtn" iconBg={false} noarrow title="Heinz Gallenbach" />
            </div>
        </>
    )
}








