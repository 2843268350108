import React, { useState } from 'react';
import { DashboardImage } from '../Components/StyledComponents';


function Dashboard() {

    return (
        <>
            <DashboardImage src="/dashboard.jpg"/>
        </>
    );
}

export default Dashboard;