import React from 'react';
import { Outlet } from 'react-router';
import AccountFooter from './AccountFooter';
import Link from '@mui/material/Link';
import styled from 'styled-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function AccountLayout() {
    return (
        <>
            <div className='account-wrapper'>
                {/* <BackLink /> */}
                <Outlet />
                <AccountFooter />
            </div>
        </>
    );
}
function BackLink() {
    const LinkWrapper = styled.div`
    width:100%;
    padding-left:30px;
    padding-top:30px;
    display:flex;
    align-items:center;
    color:#120ec4 !important;
    font-size:14px !important;
    `
    return (
        <>
            <LinkWrapper>
                <ArrowBackIcon style={{ fontSize: '18px' }} />
                <Link sx={{ ml: '5px' }} className='over-underline'>Zur Webseite</Link>
            </LinkWrapper>
        </>
    )
}
export default AccountLayout;