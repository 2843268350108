import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';


const AvatarImage = styled.div`
width:${props => !!props.width  ? props.width + "px" : "30px"};
height:${props => !!props.height ? props.height + "px" : "30px"};
border-radius:50%;
&.radioImg {
    width: 26px;
    height: 26px;
}
&.table {
    margin-right:5px;
}
& img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

`
const UserNameCircle = styled.div`
border:none;
font-weight:400;
display: flex;
align-items: center;
cursor:pointer;
width:${props => !!props.width  ? props.width + "px" : "30px"};
height:${props => !!props.height ? props.height + "px" : "30px"};
padding: 5px;
justify-content: center ;
  border-radius: 50% ;
  background: #f3efec ;
  background-color: #f3efec ;
  ${props => !props.noMargin && " margin-bottom: 15px;"}
 color:#ad9b95;
  font-size: ${props => !!props.width  ? (props.width / 2) + "px" : "30px"};
&.table {
    margin-right:5px;
}
`

function Avatar(props) {
    const {src,isLogged,usernameProps} = props;
    const username = localStorage.getItem("UserName");
    const img = localStorage.getItem("UserImage");

    const GetFirstLetters = (str) => {
        var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
        var acronym = matches.join('');
        return acronym
    }

    const Template = () => {
       if(isLogged){
       
        if(img){
            return (
                <AvatarImage {...props} >
                    <img className='w-100' src={img} onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src="https://www.pngarts.com/files/6/User-Avatar-in-Suit-PNG.png";
                                }} />
                </AvatarImage>
            )
        } else {
            return (
                <UserNameCircle {...props}>
                   {username && GetFirstLetters(username)}
                </UserNameCircle>
               )
        }
       } else {
        if(src){
            return (
                <AvatarImage className={`${src && "radioImg"}`} {...props} >
                    <img className='w-100' src={src } onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src="https://www.pngarts.com/files/6/User-Avatar-in-Suit-PNG.png";
                                }} />
                </AvatarImage>
            )
        } else {
            if(usernameProps){
                return (
                    <UserNameCircle {...props}>
                       {usernameProps && GetFirstLetters(usernameProps)}
                    </UserNameCircle>
                   )
            } else {
                return (
                    <UserNameCircle {...props}>
                       {username && GetFirstLetters(username)}
                    </UserNameCircle>
                   )
            }
           
        }
       }
       
    }

    return (
        <>
       {Template()}
        </>
    );
}

export default Avatar;