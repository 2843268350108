import axios from "axios";
import { SERVER_URL, AWS_BUCKET_URL } from './Config';
import Swal from 'sweetalert2';
import { setLoader } from './Redux/Slice/commonSlice';

export async function dynamicPostApi(data, Url, useNavigate, setCookie, dispatch) {
  dispatch(setLoader(true));
  const token = getCookie("authToken");
  return await axios({
    method: "Post",
    url: SERVER_URL + Url,
    headers: {
      Authorization: "Bearer " + token,
    },
    data: data
  })
    .then((response) => {
      dispatch(setLoader(false));
      return response;
    })
    .catch(function (error) {
      if (!!error.response) {
        if (error.response.status === 401) {
          useNavigate("/");
        }
        else if (error.response.status === 500) {
          Swal.fire({
            icon: 'error',
            title: 'Something went wrong...',
          })
        }
      }
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(setLoader(false));
      }, 1000);
    });
}

export async function dynamicGetApi(Url, useNavigate, setCookie, dispatch) {
  dispatch(setLoader(true));
  const token = getCookie("authToken");
  return await axios({
    method: "Get",
    url: SERVER_URL + Url,
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      dispatch(setLoader(false));
      if (error.response.status === 401) {
        useNavigate("/");
      }
      else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong...',
        })
      }
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(setLoader(false));
      }, 1000);
    });
}

export async function loginApi(data, setCookie, useNavigate, dispatch) {
  dispatch(setLoader(true));
  return await axios({
    method: "Post",
    url: SERVER_URL + "api/account/adminLogin",
    data: data
  })
    .then((response) => {
      if (response.status === 200) {
        setCookie('authToken', response.data.token, { path: '/', maxAge: 7200 });
        useNavigate("/dashboard");
        localStorage.setItem("UserName", response.data.userName);
        localStorage.setItem("UserImage", AWS_BUCKET_URL + response.data.userImage);
      }
      return response;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        useNavigate("/login");
      }
      else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong...',
        })
      }
      else if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Incorrect username or password.',
        })
      }
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(setLoader(false));
      }, 1000);
    });
}

export async function dynamicGetFileApi(Url, useNavigate, setCookie, dispatch) {
  dispatch(setLoader(true));
  const token = getCookie("authToken");
  return await axios({
    method: "Get",
    url: SERVER_URL + Url,
    headers: {
      Authorization: "Bearer " + token,
    },
    responseType: 'blob',
  })
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      dispatch(setLoader(false));
      if (error.response.status === 401) {
        useNavigate("/");
      }
      else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong...',
        })
      }
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(setLoader(false));
      }, 1000);
    });
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function AppLogout(removeCookie, useNavigate, setCookie) {
  removeCookie("authToken");
  useNavigate("/");
  localStorage.removeItem("UserName",);
  localStorage.removeItem("UserImage",);
}