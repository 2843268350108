import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import CloseIconWrap from '../CloseIconWrap';
import CustomizedTreeView from '../TreeView';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { dynamicGetApi, dynamicGetFileApi } from '../../API';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import { setSelectedClinicId } from '../../Redux/Slice/commonSlice';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import { FlexCenter, FlexStart } from '../StyledComponents';
import { saveAs } from 'file-saver';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '50%',
    transform: 'translate(-50%, -50%)',
    borderColor: '#fff',
    bgcolor: 'background.paper',
    borderRadius: '25px',
    boxShadow: 24,
    pt: 1.8,
    pb: 1.8,
    px: 2,
};
const ModelTitle = styled.h3`
font-size:20px;
font-weght:500 !important;
text-align:center;
margin:0px;
color:#1a1a1a !important;
`
export const SolidTextField = styled(TextField)({
    borderRadius: '8px',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#f5f5f5',
        },
        '&:hover fieldset': {
            borderColor: '#f5f5f5',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#f5f5f5',
        },
    },
});
export const RequiredText = styled.div`
font-size:13px;
color: #d32f2f;
font-weight:400;
padding-left:15px;
`
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    height: '45px',
    borderRadius: '8px',
    '& .MuiInputBase-input': {
        backgroundColor: '#f5f5f5',
        border: 'none',
        '&:focus': {
            backgroundColor: '#f5f5f5',
            border: 'none',
        },
    },
}));


function ViewFilesModal(props) {
    const [cookie, setCookie, removeCookie] = useCookies();
    let navigate = useNavigate();
    let dispatch = useDispatch();

    let selectedClinicId = useSelector(state => state.common.selectedClinicId);

    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        if (selectedClinicId) {
            getClinicFiles();
        }
    }, [selectedClinicId]);

    const getClinicFiles = async () => {
        let url = "api/clinic/getFiles?clinicId=" + selectedClinicId;
        let res = await dynamicGetApi(url, navigate, setCookie, dispatch);
        if (res.data) {
            setFileList(res.data);
        }
    }

    const onDownload = async (fileName) => {
        let url = "api/clinic/downloadFile?clinicId=" + selectedClinicId + "&filename=" + fileName;
        let res = await dynamicGetFileApi(url, navigate, setCookie, dispatch);
        if (res.data) {
            const file = new Blob([res.data]);
            saveAs(file, fileName);
        }
    }

    const onDelete = async (fileName) => {
        let url = "api/clinic/deleteFile?clinicId=" + selectedClinicId + "&filename=" + fileName;
        let res = await dynamicGetApi(url, navigate, setCookie, dispatch);
        if (res.data) {
            getClinicFiles();
        }
    }

    const onClose = () => {
        props.hideModal();
        dispatch(setSelectedClinicId(null));
        setFileList([]);
    }

    return (
        <>
            <div>
                <Modal
                    open={props.isShow}
                    onClose={() => onClose()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="position-r"
                >
                    <Grid container>
                        <Grid item md={12} sm={12} xs={12}>
                            <Box sx={style}>
                                <Box className='d-flex justify-content-between align-items-center' sx={{ py: 2, borderBottom: '1px solid #ebebeb', position: 'relative' }}>
                                    <Box>
                                        <ModelTitle>Klinik Files</ModelTitle>
                                    </Box>
                                    <Box>
                                        <Box sx={{ position: 'absolute', top: '-50px', right: '-55px' }} onClick={() => onClose()}>
                                            <CloseIconWrap />
                                        </Box>
                                        {/* <Button sx={{ mr: 2 }} type='button' className='btn-light'>Speichern</Button> */}
                                    </Box>
                                </Box>
                                <Box className="InviteColleague-modal">
                                    <Box sx={{ pb: 2 }}>
                                        <Grid container spacing={2} sx={{ mt: 1 }}>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                                                    <Table aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell sx={{ color: '#1a1a1a' }}><b>File Name</b></TableCell>
                                                                <TableCell></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {fileList.length > 0 ? fileList.map(x =>
                                                                <TableRow>
                                                                    <TableCell >
                                                                        <Box display="flex" justifyContent="start" alignItems="center">
                                                                            <Box>
                                                                                <Typography varient='p' component='p' sx={{ fontSize: '16px', margin: 0 }}>{x}</Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Box>
                                                                            <FlexCenter>
                                                                                <span className='icon-box' onClick={() => onDownload(x)}>
                                                                                    <CloudDownloadIcon className='icon-color' />
                                                                                </span>
                                                                                <span className='icon-box ml-20px' onClick={() => onDelete(x)}>
                                                                                    <DeleteIcon className='icon-color' />
                                                                                </span>
                                                                            </FlexCenter>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ) :
                                                                <TableRow>
                                                                    <TableCell colSpan={2}>
                                                                        <Box display="flex" justifyContent="center" alignItems="center">
                                                                            <Box>
                                                                                <Typography varient='p' component='p' sx={{ fontSize: '16px', margin: 0 }}>No data available</Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                {/* <CustomizedTreeView /> */}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Modal>
            </div>
        </>
    );
}

export default ViewFilesModal;