import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Popover from '@mui/material/Popover';
import { Navigate, useNavigate } from 'react-router-dom';
import NightlightRoundIcon from '@mui/icons-material/NightlightRound';
import Avatar from './Avatar';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import { Box } from '@mui/system';
import { setIsEdit, setRecall, setSelectedClinicId } from './../Redux/Slice/commonSlice';
import { CustomWrapper, EllipsisText, GallenbachButton, HeaderButton, HeaderButtonLeftIcon, HeaderButtonarrow, PaddingLeft15, PlanarternHeading } from './StyledComponents';
import { AppLogout, dynamicGetApi } from '../API';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import { setSeletedUserId } from '../Redux/Slice/userSlice';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';


const EditText = styled.span`
font-size:16px;
color:#1a1a1a;
margin-left:5px;
`


export function DropdownWrapper(props) {
  const { icon, title, iconBg, noarrow, buttonClass, bodyType, position } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dropdownBody = {
    "ProfileDropdown": <ProfileDropdown title={title} />,
    "TableAction": <TableAction clinicId={props.clinicId} closeDropdown={handleClose} setColleagueModal={props.setColleagueModal} setClinicModal={props.setClinicModal} setFilesModal={props.setFilesModal} userId={props.userId} tableName={props.tableName} />,
  }
  return (
    <div>
      <div>
        {bodyType !== "ProfileDropdown" ?
          <HeaderButton
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            className={buttonClass}
          >
            {icon && <HeaderButtonLeftIcon bg={iconBg}>
              {icon}
            </HeaderButtonLeftIcon>}
            {title}
            {!noarrow && <HeaderButtonarrow>
              <ExpandMoreIcon color='inherit' fontSize='inherit' />
            </HeaderButtonarrow>}
          </HeaderButton>
          :
          <div style={{ marginLeft: "10px" }}><Avatar isLogged onClick={handleClick} noMargin username={title} width={30} height={30} /></div>
        }
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          className={`${bodyType === "EditFile" ? "EditFilePopover" : bodyType === 'ChatTemplates' ? 'ChatTemplatesPopover' : "CustomPopoverStyle"}`}
          anchorOrigin={{
            vertical: bodyType === 'ChatTemplates' ? 'top' : 'bottom',
            horizontal: bodyType === 'ChatTemplates' ? 'left' : (position ? "right" : 'left'),
          }}
          transformOrigin={{
            vertical: bodyType === 'ChatTemplates' ? 'bottom' : 'top',
            horizontal: bodyType === 'ChatTemplates' ? 'right' : (position ? "right" : 'left'),
          }}
        >
          <div className='PopOverWrapper'>
            {dropdownBody[bodyType]}
          </div>
        </Popover>
      </div>
    </div>
  );
}

const ProfileDropdown = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
  let navigate = useNavigate();
  return (
    <CustomWrapper width={"220px"} className='ProfileWrapper'>
      <PaddingLeft15>
        <Avatar isLogged={true} width={36} height={36} />
        <div className='mb-3'></div>
        <PlanarternHeading className='fw-bold'>
          {localStorage.getItem("UserName")}
        </PlanarternHeading>
      </PaddingLeft15>
      <GallenbachButton className='w-100 ' onClick={() => AppLogout(removeCookie, navigate, setCookie)}>
        <HeaderButtonLeftIcon className='pl-0' newBg={""} color='#bababa' >
          <NightlightRoundIcon />
        </HeaderButtonLeftIcon>
        <div>Abmelden</div>

      </GallenbachButton>

    </CustomWrapper>

  )
}
const TableAction = (props) => {
  const [setCookie] = useCookies();
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const onEditClick = () => {
    dispatch(setSeletedUserId(props.userId));
    dispatch(setIsEdit(true));
    props.setColleagueModal(true);
  }

  const onAddEditClinicClick = () => {
    dispatch(setSelectedClinicId(props.clinicId));
    props.setClinicModal(true);
  }

  const onTeamClick = () => {
    navigate(`/dashboard/team/${props.clinicId}`);
  }
  const onFileViewClick = () => {
    props.setFilesModal(true);
    dispatch(setSelectedClinicId(props.clinicId));
  }

  const onDeleteClinicClick = async () => {
    Swal.fire({
      text: 'Do you want delete this clinic.',
      icon: 'error',
      showCancelButton: true,
      confirmButtonColor: '#120EC5',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let url = "api/clinic/deleteClinic?clinicId=" + props.clinicId;
        let res = await dynamicGetApi(url, navigate, setCookie, dispatch);
        if (res.data) {
          dispatch(setRecall(true));
          Swal.fire(
            'Deleted!',
            'Delete successfully.',
            'success'
          )
        }
      }
    })
  }

  return (
    <div className='GallenbachZahneZahnarztpraxisWrapper'>
      {props.tableName === "ManageClinic" ?
        <Box>
          <GallenbachButton className='w-100' onClick={() => { onAddEditClinicClick(); props.closeDropdown() }}>
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
              <EditIcon style={{ color: '#bababa', fontSize: '25px' }} />
              <EditText>Klinik bearbeiten</EditText>
            </Box>
          </GallenbachButton>
          <GallenbachButton className='w-100' onClick={() => { onTeamClick(); props.closeDropdown() }}>
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
              <CalendarViewMonthIcon style={{ color: '#bababa', fontSize: '25px' }} />
              <EditText>Team</EditText>
            </Box>
          </GallenbachButton>
          <GallenbachButton className='w-100' onClick={() => { onFileViewClick(); props.closeDropdown() }}>
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
              <AccountTreeOutlinedIcon style={{ color: '#bababa', fontSize: '25px' }} />
              <EditText>View Files</EditText>
            </Box>
          </GallenbachButton>
          <GallenbachButton className='w-100' onClick={() => { onDeleteClinicClick(); props.closeDropdown() }}>
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
              <DeleteIcon style={{ color: '#bababa', fontSize: '25px' }} />
              <EditText>Löschen</EditText>
            </Box>
          </GallenbachButton>
        </Box > :
        <GallenbachButton className='w-100' onClick={() => { onEditClick(); props.closeDropdown() }}>
          <Box sx={{ p: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
            <EditIcon style={{ color: '#bababa', fontSize: '25px' }} />
            <EditText>Person editieren</EditText>
          </Box>
        </GallenbachButton>}
    </div>

  )
}


