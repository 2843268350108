import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import styled from 'styled-components';
import ClearIcon from '@mui/icons-material/Clear';
import ImageUploader from '../ImageUploader';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputBase from '@mui/material/InputBase';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { dynamicGetApi, dynamicPostApi } from '../../API';
import Swal from 'sweetalert2';
import { setRecall, setIsEdit } from './../../Redux/Slice/commonSlice';
import { setSeletedUserId } from './../../Redux/Slice/userSlice';
import CloseIconWrap from '../CloseIconWrap';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '50%',
    transform: 'translate(-50%, -50%)',
    borderColor: '#fff',
    bgcolor: 'background.paper',
    borderRadius: '25px',
    boxShadow: 24,
    pt: 2,
    pb: 3,
    px: 2,
};
const ModelTitle = styled.h3`
font-size:20px;
font-weght:500 !important;
text-align:center;
margin:0px;
color:#1a1a1a !important;
`

export const SolidTextField = styled(TextField)({
    borderRadius: '8px',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#f5f5f5',
        },
        '&:hover fieldset': {
            borderColor: '#f5f5f5',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#f5f5f5',
        },
    },
});

const RequiredText = styled.div`
font-size:13px;
color: #d32f2f;
font-weight:400;
padding-left:15px;
`

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    height: '40px',
    borderRadius: '8px',
    '& .MuiInputBase-input': {
        backgroundColor: '#f5f5f5',
        border: 'none',
        '&:focus': {
            backgroundColor: '#f5f5f5',
            border: 'none',
        },
    },
}));
function EinladenModal(props) {
    const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const isEdit = useSelector(state => state.common.isEdit);

    const [roleList, setRoleList] = useState([]);
    const [userImgSrc, setUserImgSrc] = useState('');

    const formik = useFormik({
        initialValues: {
            id: 0,
            userName: '',
            email: '',
            role: '',
            userImage: '',
            imageName: '',
        },
        validationSchema: Yup.object().shape({
            userName: Yup.string().required("Required *"),
            email: Yup.string().required("Required *"),
            role: Yup.string().required("Required *"),
        }),
        onSubmit: async values => {
            let inviteToUserUrl = "api/user/inviteDmapsoftUser"
            var form_data = new FormData();

            for (var key in values) {

                form_data.append(key, values[key]);
            }
            let result = await dynamicPostApi(form_data, inviteToUserUrl, navigate, setCookie, dispatch);
            if (result.data) {
                onClose();
                Swal.fire({
                    title: 'Invitation Sent',
                    text: 'Invitation sent successfully!',
                    icon: 'success',
                    confirmButtonColor: '#3085d6',
                }).then((result) => {
                    if (result.isConfirmed) {
                        dispatch(setRecall(true));
                    }
                })
            }
        },
    });

    const setImage = (img) => {
        formik.setFieldValue("userImage", img);
    }

    useEffect(() => {
        if (props.isShow) {
            getPageData();
        }
    }, [props.isShow]);

    const getPageData = async () => {
        let url = "api/role/getRoles";
        let roleListRes = await dynamicGetApi(url, navigate, setCookie, dispatch);
        setRoleList(roleListRes.data);

        let getSelectedUserUrl = "api/user/getDmapsofUserById?userId=" + props.selectUserId;
        let selectedUserRes = await dynamicGetApi(getSelectedUserUrl, navigate, setCookie, dispatch);
        setUserImgSrc(selectedUserRes.data.image);
        formik.setFieldValue("id", selectedUserRes.data.id);
        formik.setFieldValue("userName", selectedUserRes.data.fullName);
        formik.setFieldValue("email", selectedUserRes.data.email.trim());
        formik.setFieldValue("userImage", selectedUserRes.data.image);
        formik.setFieldValue("imageName", selectedUserRes.data.image);
    }

    const onClose = () => {
        formik.resetForm();
        props.hideEinladenModal();
        dispatch(setSeletedUserId(0));
    }

    return (
        <>
            <div>
                <Modal
                    open={props.isShow}
                    onClose={() => onClose()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="position-r"
                >
                    <Box sx={style}>
                        <Box className='d-flex justify-content-between align-items-center' sx={{ pb: 1, borderBottom: '1px solid #ebebeb', position: 'relative' }}>

                            <Box>
                                <ModelTitle>{formik.values.userName} Einladen</ModelTitle>
                            </Box>
                            <Box>
                                <Box sx={{ position: 'absolute', top: '-50px', right: '-55px' }} onClick={() => onClose()}>
                                    <CloseIconWrap />
                                </Box>
                                <Button sx={{ mr: 2 }} type='button' onClick={formik.handleSubmit} className='btn-light'>Einladen</Button>
                            </Box>
                        </Box>

                        <Box className='Einladen-modal'>
                            <Box>
                                <ImageUploader setUserImage={setImage} isEditMode={isEdit} imgSrc={userImgSrc} />
                            </Box>

                            <Grid container spacing={2} sx={{ mt: 2 }}>
                                <Grid item md={12} sm={12} xs={12}>
                                    <FormLabel>Vor-und Nachname</FormLabel>
                                    <SolidTextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        placeholder='Vor-und Nachname'
                                        id="userName"
                                        name="userName"
                                        size="small"
                                        type="text"
                                        sx={{ pt: '5px', mb: 2, mt: 1, height: '45px', backgroundColor: '#f5f5f5', }}
                                        error={formik.errors.userName && formik.touched.userName ? true : false}
                                        helperText={formik.errors.userName && formik.touched.userName ? formik.errors.userName : ""}
                                        value={formik.values.userName}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <FormLabel>E-Mail</FormLabel>
                                    <SolidTextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        placeholder='E-Mail'
                                        id="email"
                                        name="email"
                                        size="small"
                                        type="text"
                                        sx={{ pt: '5px', mb: 2, mt: 1, height: '45px', backgroundColor: '#f5f5f5', }}
                                        error={formik.errors.email && formik.touched.email ? true : false}
                                        helperText={formik.errors.email && formik.touched.email ? formik.errors.email : ""}
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <FormLabel id="role">Rolle</FormLabel>
                                    <Select
                                        labelId="role"
                                        id="demo-simple-select"
                                        value={formik.values.role}
                                        name='role'
                                        label=""
                                        input={<BootstrapInput />}
                                        sx={{ width: '100%', height: '45px', backgroundColor: '#f5f5f5' }}
                                        onChange={formik.handleChange}
                                    >
                                        {roleList.map(x =>
                                            <MenuItem value={x.value}>{x.label}</MenuItem>
                                        )}
                                    </Select>
                                    <RequiredText>
                                        {formik.touched.role && formik.errors.role
                                            ? formik.errors.role
                                            : null}
                                    </RequiredText>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Modal>
            </div>
        </>
    );
}

export default EinladenModal;