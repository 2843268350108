import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  seletedUserId: null
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSeletedUserId: (state, action) => {
      state.seletedUserId = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setSeletedUserId} = userSlice.actions;

export default userSlice.reducer
