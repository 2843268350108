import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoader: false,
  recall: false,
  isEdit: false,
  selectedClinicId: null
}

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.isLoader = action.payload
    },
    setRecall: (state, action) => {
      state.recall = action.payload
    },
    setIsEdit: (state, action) => {
      state.isEdit = action.payload
    },
    setSelectedClinicId: (state, action) => {
      state.selectedClinicId = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setLoader, setRecall, setIsEdit, setSelectedClinicId} = commonSlice.actions;

export default commonSlice.reducer
