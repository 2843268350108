import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import ClinicTable from '../Components/Tables/ClinicTable';
import AddEditClinic from '../Components/Dialogs/AddEditClinic';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { styled, alpha } from '@mui/material/styles';
import { dynamicGetApi } from '../API';
import { setRecall } from '../Redux/Slice/commonSlice';
import Swal from 'sweetalert2';
import ViewFilesModal from '../Components/Dialogs/ViewFilesModal';


export const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#f5f5f5',
    borderRadius: '20px',
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));
export const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
    },
}));

function ManageClinic(props) {
    const [cookie, setCookie, removeCookie] = useCookies();
    let navigate = useNavigate();
    let dispatch = useDispatch();

    let recall = useSelector(state => state.common.recall);

    const [addEditClinicModal, setAddEditClinicModal] = useState(false);
    const [viewFilesModal, setViewFilesModal] = useState(false);
    const [clinicList, setClinicList] = useState([]);
    const [clinicListForFilter, setClinicListForFilter] = useState([]);

    useEffect(() => {
        getClinicList();
    }, []);

    useEffect(() => {
        if(recall){
            getClinicList();
            dispatch(setRecall(false));
        }
    }, [recall]);

    const getClinicList = async () => {
        let url = "api/clinic/getClinicList";
        let res = await dynamicGetApi(url, navigate, setCookie, dispatch);
        setClinicListForFilter(res.data);
        setClinicList(res.data);
    }

    const changeDsWorkerStatus = async (clinic) => {
        let status = !clinic.dsWorkerStatus ?'Authorize' : 'Unauthorize';
        Swal.fire({
            title: status + ' DSWoker?',
            text: 'Do you want ' + status + ' this DSWoker to update the cloud database.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#120EC5',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, ' + status + '!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let url = 'api/clinic/changeDsWorkerStatus?clinicId=' + clinic.id;
                let res = await dynamicGetApi(url, navigate, setCookie, dispatch);
                if (res.data) {
                    dispatch(setRecall(true));
                    Swal.fire(
                        status + '!',
                        status + ' successfully.',
                        'success'
                    )
                }
            }
        })
    }
   
    const changeGeneralWorkerStatus = async (clinic) => {
        let status = !clinic.generalWorkerStatus ?'Authorize' : 'Unauthorize';
        Swal.fire({
            title: status + ' DSWoker?',
            text: 'Do you want ' + status + ' this General Woker to update the cloud database.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#120EC5',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, ' + status + '!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let url = 'api/clinic/changeGeneralWorkerStatus?clinicId=' + clinic.id;
                let res = await dynamicGetApi(url, navigate, setCookie, dispatch);
                if (res.data) {
                    dispatch(setRecall(true));
                    Swal.fire(
                        status + '!',
                        status + ' successfully.',
                        'success'
                    )
                }
            }
        })
    }

    const filterRecord = (e) => {
        let filterValue = e.target.value;
        if(!!filterValue){
            let filteredRecord = clinicListForFilter.filter(x => x.clinicName.includes(filterValue) || x.id.toString().includes(filterValue));
            setClinicList(filteredRecord);
        }else{
            setClinicList(clinicListForFilter);
        }
    }
    return (
        <>
            <Box  className='p-mob'>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 4 }} className='flex-column-mob'>
                    <Typography variant='h5' component='h5' sx={{ fontWeight: '600', color: '#1a1a1a' }}>Klinik Verwalten</Typography>
                    <Button className='btn-light practiceSetting-btn-mt' onClick={() => setAddEditClinicModal(true)}>Klinik Hinzufügen</Button>
                </Box>
                <Box sx={{ mt: 0, mb: 3 }}>
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={(e) => filterRecord(e)}
                        />
                    </Search>
                </Box>
                <ClinicTable changeDsWorkerStatus={changeDsWorkerStatus} changeGeneralWorkerStatus={changeGeneralWorkerStatus} setViewFilesModal={setViewFilesModal} setAddEditClinicModal={setAddEditClinicModal} clinicList={clinicList}/>
            </Box>
            <AddEditClinic isShow={addEditClinicModal} hideModal={() => setAddEditClinicModal(false)} />
            <ViewFilesModal isShow={viewFilesModal} hideModal={() => setViewFilesModal(false)} />
        </>
    );
}

export default ManageClinic;